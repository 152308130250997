<template>
  <div class="product-page--create">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Upsell offers" route="website.apps.upsell.list" />
        <div class="page-header">
          <h2 class="page-title">Create Offer</h2>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12"> <Notification type="error" :messages="errors" v-if="errors.length > 0" /> </v-col>
    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <h4>Choose offer types</h4>
      </v-col>
      <ListType />
    </v-row>
    <v-col cols="12" class="my-3">
      <v-divider class=""></v-divider>
    </v-col>
    <PrePurchase v-if="idActiveType === 1" />
    <InCart v-if="idActiveType === 2" />
    <PostPurchase v-if="idActiveType === 3" />
    <v-col cols="12" class="my-3">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="d-flex" v-if="idActiveType !== 3">
      <v-spacer></v-spacer>
      <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
      <!-- có thể .once do sau khi click sẽ chuyển router qua màn update, k ở lại màn cũ -->
      <v-btn :loading="isLoading" color="primary" @click.once="onSubmit">Create</v-btn>
    </v-col>
    <BeforeLeavePage />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import resourceBeforeLeavePage from '@/mixins/before-change-router';
import { collectionApi } from '@/apis/collection';
import PrePurchase from './components/PrePurchase';
import InCart from './components/InCart';
import PostPurchase from './components/PostPurchase';
import { offerApi } from '@/apis/offers';

import ListType from './components/ListType';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    ListType,
    PrePurchase,
    InCart,
    PostPurchase,
  },
  data() {
    return {
      errors: [],
      idActiveType: 1,
      configSelectionItem: {
        type: 'products',
        model: 'targetIds',
        data: [],
      },
      dialog: false,
      isLoading: false,
      typeTarget: 'all',
      itemsMessage: [
        {
          name: 'Frequently bought with {{product name}}',
          id: 'Frequently bought with {{product name}}',
        },
        {
          name: 'Complete your order with {{product name}}',
          id: 'Complete your order with {{product name}}',
        },
        {
          name: 'Best deal today for lucky customers like you',
          id: 'Best deal today for lucky customers like you',
        },
      ],
      formData: {
        sameProductType: false,
        activated: true,
        offerMessage: 'Complete your order with {{product name}}',
        offerType: 'prePurchase',
        priority: 0,
        offerName: '',
        offerTitle: null,
        recommendIds: [], // product collection ids ,
        recommendType: 'sameCollection', //product, collection, sameCollection, relevantCollection
        targetIds: [],
        targetType: 'all', //all, product, collection
        store: '',
        enableDiscount: true,
        percentageDiscount: 0,
      },
    };
  },
  validations: {
    formData: {
      offerName: {
        required,
      },
    },
  },
  methods: {
    onCancel() {
      this.$v.$reset();

      this.$forceUpdate();
      //website.collections
    },
    async sendResquest() {
      let convertData = Object.assign({}, this.formData);
      convertData.targetIds = convertData.targetIds.map(item => item._id);
      convertData.recommendIds = convertData.recommendIds.map(item => item._id);
      convertData.store = this.$store.state.auth.accountInfo.storeId;
      if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
        convertData[`${this.formData.targetType}TargetIds`] = convertData.targetIds;
      }
      if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
        convertData[`${this.formData.recommendType}RecommendIds`] = convertData.recommendIds;
      }
      delete convertData.recommendIds;
      delete convertData.targetIds;
      try {
        let response = await offerApi.create({ offer: convertData });
        this.$router.push({ name: 'website.apps.upsell.update', params: { id: response.data.offer._id } });
      } catch (error) {}
    },
    async onSubmit() {
      this.$v.$touch();
      this.isLoading = true;
      if (!this.$v.$invalid && this.checkValidate()) {
        this.sendResquest();
      }
      this.isLoading = false;
    },
    checkValidate() {
      let [targetType, recommendType, targetIds, recommendIds] = [
        this.formData.targetType,
        this.formData.recommendType,
        this.formData.targetIds,
        this.formData.recommendIds,
      ];
      if (
        targetType === 'all' &&
        (recommendType === 'sameCollection' ||
          recommendType === 'relevantCollection' ||
          recommendType === 'sameNiche' ||
          recommendType === 'sameTitle')
      ) {
        return true;
      }
      if (
        (targetType === 'all' || (targetType !== 'all' && targetIds.length > 0)) &&
        (recommendType === 'sameCollection' ||
          recommendType === 'relevantCollection' ||
          recommendType === 'sameNiche' ||
          recommendType === 'sameTitle' ||
          ((recommendType !== 'sameCollection' ||
            recommendType !== 'relevantCollection' ||
            recommendType !== 'sameNiche' ||
            recommendType !== 'sameTitle') &&
            recommendIds.length > 0))
      ) {
        return true;
      } else return false;
    },
    configDialogSelection(data) {
      this.dialog = true;
      this.configSelectionItem = Object.assign({}, data);
      if (this.formData.targetIds && data.model === 'targetIds') {
        this.configSelectionItem.data = this.formData.targetIds;
      }
      if (this.formData.recommendIds && data.model === 'recommendIds') {
        this.configSelectionItem.data = this.formData.recommendIds;
      }
    },
    getDataSelected(data, model) {
      this.formData[model] = JSON.parse(JSON.stringify(data));
    },
  },
  mounted() {},
  watch: {
    'formData.targetType': function() {
      this.formData.targetIds = [];
    },
    'formData.recommendType': function() {
      this.formData.recommendIds = [];
    },
    idActiveType: function() {
      this.$router.push({
        query: {
          tab: this.idActiveType,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.product-page--create {
  .btn-link {
    color: var(--v-primary-base);
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
